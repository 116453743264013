import store from "../store";
import CampaignService from "@/services/campaignService";

export default async function () {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const {
        clickIdParameterName,
        publisherIdParameterName,
    } = await getCampaignConfig(campaignId);
    const publisherId = urlParams.get(`${publisherIdParameterName}`) ?? urlParams.get('publisherId');
    const clickId = urlParams.get(`${clickIdParameterName}`) ?? urlParams.get('clickid') ?? urlParams.get('clickId') ?? urlParams.get('click_id') ?? urlParams.get('ofreqid') ?? urlParams.get('transaction_id') ?? urlParams.get('pr_mbp_clickID');
    const googleClickId = urlParams.get('gclid') ?? urlParams.get('tmt_data');

    setCampaignParametersInStore(campaignId, googleClickId, clickId, publisherId);
    return {
        campaignId: campaignId,
        googleClickId: googleClickId,
        clickId: clickId,
        publisherId: publisherId,
    }

}

async function getCampaignConfig(campaignId) {
    if(!campaignId) return {result: false}
    const {
        clickIdParameterName,
        publisherIdParameterName,
        result
    } = await CampaignService.getCampaignConfig(campaignId);
    return {
        clickIdParameterName,
        publisherIdParameterName,
        result
    }
}

function setCampaignParametersInStore(campaignId, googleClickId, clickId, publisherId) {
    if (campaignId) store.commit('setCampaignId', campaignId);
    if (clickId) store.commit('setClickId', clickId);
    if (googleClickId) store.commit('setGoogleClickId', googleClickId);
    if (publisherId) store.commit('setPublisherId', publisherId);
}
