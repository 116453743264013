import {brandConfigObjectMap,} from "./brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('mtn-za-yr'));
brandConfigMap.set('http://192.168.88.91:8080', getBrandConfig('mtn-za-uy'));
brandConfigMap.set('https://web.mzansigames.com', getBrandConfig('mtn-za-lm'));
brandConfigMap.set('https://web.unlockyellow.mobi', getBrandConfig('mtn-za-uy'));
brandConfigMap.set('https://play.mzrhythm.co.za', getBrandConfig('mtn-za-mr'));
brandConfigMap.set('https://web.yellowrush.co.za', getBrandConfig('mtn-za-yr'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        gameTag: brandConfigObject.gameTag,
        gameUrl: brandConfigObject.gameUrl,
        termsConditionsUrl: brandConfigObject.termsConditionsUrl,
        ussdString: brandConfigObject.ussdString,
        baseRoute: brandConfigObject.baseRoute,
        prizePool: brandConfigObject.prizePool,
        pricePoint: brandConfigObject.pricePoint,
        productId : brandConfigObject.productId,
        serviceId : brandConfigObject.serviceId,
        gtmIdentifier : brandConfigObject.gtmIdentifier
    }
}
