export const brandConfigObjectMap = new Map();

export const Brands = {
    MtnUnlockYello: 'mtn-za-uy',
    MtnLuckyMzansi: 'mtn-za-lm',
    MtnMzansiRhythm: 'mtn-za-mr',
    MtnYelloRush: 'mtn-za-yr'
};

export const GameToBrandMap = new Map();
GameToBrandMap.set('unlock-yello', Brands.MtnUnlockYello);
GameToBrandMap.set('lucky-mzansi', Brands.MtnLuckyMzansi);
GameToBrandMap.set('mzansi-rhythm', Brands.MtnMzansiRhythm);
GameToBrandMap.set('yello-rush', Brands.MtnYelloRush);

brandConfigObjectMap.set(Brands.MtnUnlockYello, {
    gameTitle: `Unlock Y'ello`,
    gameTag: 'unlock-yello',
    gameUrl: 'http://unlockyellow.mobi',
    termsConditionsUrl: 'http://unlockyellow.mobi/terms_and_conditions',
    ussdString: '*120*070#',
    productId: 4,
    serviceId: 6416,
    baseRoute: '/opt-in-confirmation',
    pricePoint: 'R3/day',
});

brandConfigObjectMap.set(Brands.MtnLuckyMzansi, {
    gameTitle: 'Lucky Mzansi',
    gameTag: 'lucky-mzansi',
    gameUrl: 'https://lucky.mzansigaming.com',
    termsConditionsUrl: 'https://lucky.mzansigaming.com/terms-conditions',
    productId: 4,
    serviceId: 9133,
    baseRoute: '/opt-in-confirmation',
    pricePoint: 'R3/day',
    gtmIdentifier: 'GTM-TLMWGJC9',
});

brandConfigObjectMap.set(Brands.MtnMzansiRhythm, {
    gameTitle: 'Mzansi Rhythm',
    gameTag: 'mzansi-rhythm',
    gameUrl: 'https://mzrhythm.com',
    termsConditionsUrl: 'https://mzrhythm.com/terms-and-conditions',
    productId: 35,
    serviceId: 10588,
    // ussdString: '*123*321#',
    baseRoute: '/opt-in-confirmation',
    pricePoint: 'R20/week',
    gtmIdentifier: 'GTM-TLMWGJC9',
});

brandConfigObjectMap.set(Brands.MtnYelloRush, {
    gameTitle: 'Yello Rush',
    gameTag: 'yello-rush',
    gameUrl: 'https://www.yellorush.co.za',
    termsConditionsUrl: 'https://www.yellorush.co.za/terms-conditions',
    productId: 4,
    serviceId: 10531,
    baseRoute: '/opt-in-confirmation',
    pricePoint: 'R5/day',
    gtmIdentifier: 'GTM-5HN4QPSH',
});



