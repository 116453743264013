import store from "../store";
import {Brands} from "@/brand/brandConfig";
import DepService from "@/services/depService";
import DcbService from "@/services/dcbService";


export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;

    switch (brand) {
        case Brands.MtnLuckyMzansi:
        case Brands.MtnUnlockYello:
        case Brands.MtnMzansiRhythm:
        case Brands.MtnYelloRush:
            await userAuthenticateHeaderEnrichment(to, from, next);
            break;
    }
}

async function userAuthenticateHeaderEnrichment(to, from, next) {
    if (to.path === '/wifi' || to.path === '/thank-you') {
        return next();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const eMsisdn = urlParams.get('e-msisdn');

    if (eMsisdn) {
        return await encryptedMsisdnAuth(eMsisdn, next);
    }
    return next();
}

async function encryptedMsisdnAuth(eMsisdn, next) {
    const {rawMsisdn} = (await DcbService.decryptMsisdn(eMsisdn)).data;
    setMsisdn(rawMsisdn);
    const {status} = await DepService.checkUserSubscription(rawMsisdn, store.state.brandConfig.serviceId);
    return await redirectUserBasedOnUserStatusMarketingFlow(eMsisdn, status, next, rawMsisdn);
}

async function redirectUserBasedOnUserStatusMarketingFlow(eMsisdn, subscriptionStatus, next, msisdn) {
    if (subscriptionStatus === "ACTIVE") return await encryptedMsisdnProductLogin(eMsisdn);
    if (subscriptionStatus !== "ACTIVE") return await subscribeUserAndRedirect(msisdn, next);
}

function setMsisdn(msisdn) {
    store.commit('setMsisdn', msisdn);
}

async function subscribeUserAndRedirect(msisdn, next) {
    const {user} = await DepService.doiOne(msisdn, store.state.brandConfig.productId, store.state.brandConfig.serviceId);
    const {result} = await DepService.subscription(msisdn, store.state.brandConfig.productId, user.subscription_id, store.state.brandConfig.serviceId, store.state.campaignId, store.state.googleClickId, store.state.clickId, store.state.publisherId, store.state.subPublisherId);
    if (result === true) {
        next({path: `/thank-you`});
    } else {
        console.log('error');
    }
}

async function encryptedMsisdnProductLogin(eMsisdn) {
    window.open(`${store.state.brandConfig.gameUrl}/?e-msisdn=${eMsisdn}`, "_self");
}







