import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class CampaignService {
    static async getCampaignConfig(campaignId) {
        try {
            const response = await axios.post(`${environmentConfig.campaignService}/campaign/get-campaign-config`, {
                campaignId: campaignId,
            });
            return response.data;
        } catch (e) {
            return {authError: true};
        }
    }
}
