export default function (gtmTagIdentifier) {
    loadGoogleTagScripts(gtmTagIdentifier);
}

function loadGoogleTagScripts(gtmTagIdentifier) {
    loadGTM(gtmTagIdentifier)
}

function loadGTM(gtmId) {
    if (!gtmId) {
        console.error("GTM ID is required");
        return;
    }

    if (document.getElementById("gtm-script")) {
        console.warn("GTM script is already loaded.");
        return;
    }

    const gtmScript = document.createElement("script");
    gtmScript.id = "gtm-script";
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    document.head.appendChild(gtmScript);

    const gtmNoscript = document.createElement("noscript");
    gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.body.appendChild(gtmNoscript);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "gtm.js" });

    console.log(`Google Tag Manager (${gtmId}) loaded.`);
}

export function triggerGTMEvent(eventName, eventData = {}) {
    if (window.dataLayer && typeof window.dataLayer.push === "function") {
        window.dataLayer.push({
            event: eventName,
            ...eventData
        });
        console.log(`GTM Event Triggered: ${eventName}`, eventData);
    } else {
        console.warn("GTM dataLayer is not available.");
    }
}

