import {createStore} from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    brandConfig: undefined,
    msisdn: undefined,
    serviceId: undefined,
    campaignId: undefined,
    clickId: undefined,
    googleClickId: undefined,
    subPublisherId: undefined,
    publisherId: undefined,
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setBrandConfig(state, brandConfig) {
      state.brandConfig = brandConfig
    },
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn
    },
    setServiceId(state, serviceId) {
      state.serviceId = serviceId
    },
    setCampaignId(state, campaignId) {
      state.campaignId = campaignId
    },
    setClickId(state, clickId) {
      state.clickId = clickId
    },
    setGoogleClickId(state, googleClickId) {
      state.googleClickId = googleClickId
    },
    setSubPublisherId(state, subPublisherId) {
      state.subPublisherId = subPublisherId
    },
    setPublisherId(state, publisherId) {
      state.publisherId = publisherId
    },
  },
  actions: {},
  modules: {}
})
