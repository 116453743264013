export const productionConfig = {
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    depService : `${process.env.VUE_APP_DEP_SERVICE}`,
    unlockMoreService : `${process.env.VUE_APP_UNLOCK_MORE_SERVICE}`,
    headerEnrichmentService : `${process.env.VUE_APP_HEADER_ENRICHMENT_SERVICE}`,
    sdpService : `${process.env.VUE_APP_SDP_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    campaignService: `${process.env.VUE_APP_CAMPAIGN_SERVICE}`,
}

export const stagingConfig = {
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    depService : `${process.env.VUE_APP_DEP_SERVICE}`,
    unlockMoreService : `${process.env.VUE_APP_UNLOCK_MORE_SERVICE}`,
    headerEnrichmentService : `${process.env.VUE_APP_HEADER_ENRICHMENT_SERVICE}`,
    sdpService : `${process.env.VUE_APP_SDP_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    campaignService: `${process.env.VUE_APP_CAMPAIGN_SERVICE}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
